import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, containerless } from 'aurelia-framework';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { ClientInfo, Product } from 'models/api';

@containerless
@autoinject
export class SubscribePayment {
  products: Product[];
  showNets: boolean = false;
  @bindable selectedProduct: Product;
  @bindable clientInfo: ClientInfo;

  validationController: ValidationController;
  validationRules: any;
  subscriptionCompany: any = {
    name: null,
    orgNumber: null,
    useEhf: true,
    streetAddress: null,
    streetAddress2: null,
    postalCode: null,
    region: null,
    email: null,
  };

  constructor(
    private eventAggregator: EventAggregator,
    private validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController =
      validationControllerFactory.createForCurrentScope();
  }

  attached() {
    if (localStorage.getItem('showNets')) {
      this.showNets = true;
    }

    this.setupValidation();
  }

  async setupValidation() {
    ValidationRules.ensure((it: any) => it.name)
      .required()
      .withMessage('Navn er påkrevd')

      .ensure((it: any) => it.orderer)
      .required()
      .withMessage('Navn på bestiller er påkrevd')

      .ensure((it: any) => it.email)
      .required()
      .withMessage('E-post er påkrevd')
      .email()
      .withMessage('Ugyldig epostadresse')

      .ensure((it: any) => it.streetAddress)
      .required()
      .withMessage('Adresse er påkrevd')

      .ensure((it: any) => it.postalCode)
      .required()
      .withMessage('Postnummer er påkrevd')
      .min(4)
      .withMessage('Ugyldig postnummer')

      .ensure((it: any) => it.region)
      .required()
      .withMessage('Poststed er påkrevd')

      .on(this.subscriptionCompany);
  }

  async setSubscriptionCompany() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.eventAggregator.publish(
      'subscribe:setSubscriptionCompany',
      this.subscriptionCompany
    );

    setTimeout(() => {
      this.eventAggregator.publish(
        'subscribe:selectPaymentMethod',
        'invoiceCompany'
      );
    });
  }

  async selectPaymentMethod(method: string) {
    this.eventAggregator.publish('subscribe:selectPaymentMethod', method);
  }
}
