import { autoinject } from 'aurelia-framework';
import { UserSubscription } from 'models/api';
import SubscriptionService from 'services/subscription-service';

@autoinject
export class Subscriptions {
  subscriptions: UserSubscription[];

  constructor(private subscriptionService: SubscriptionService) {}

  async activate() {
    this.getData();
  }

  async getData() {
    this.subscriptions = await this.subscriptionService.getUserSubscriptions();
  }
}
