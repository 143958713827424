import { Product } from 'models/api';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, containerless, bindable } from 'aurelia-framework';

@containerless
@autoinject
export class SubscribeSummary {
  @bindable selectedProduct: Product;
  @bindable selectedPaymentMethod: string;
  @bindable profile: any;

  constructor(private eventAggregator: EventAggregator) {}

  async purchaseSubscription(method: string) {
    this.eventAggregator.publish('subscribe:purchase');
  }
}
