import { autoinject } from 'aurelia-framework';
import { Redirect, Router } from 'aurelia-router';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import AuthService from 'services/auth-service';
import { ErrorService } from 'services/error-service';
import { NotificationsService } from 'services/notifications-service';

@autoinject
export class ResetPassword {
  errorMessage: string;
  validationController: ValidationController;
  validationRules: any;

  dto: {
    password: string;
    passwordConfirm: string;
    token: string;
  };

  emailSent: boolean = false;
  isLoading: boolean = null;
  loginUrl: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService,
    private validationControllerFactory: ValidationControllerFactory,
    private notificationsService: NotificationsService
  ) {
    this.validationController =
      validationControllerFactory.createForCurrentScope();
  }

  async canActivate(params) {
    if (localStorage.getItem('aboIdUser:loggedIn')) {
      return new Redirect('/');
    }
    if (!params.token) {
      return new Redirect('/');
    }

    const linkValid = await this.verifyLink(params.token);
    if (!linkValid) {
      return new Redirect('/');
    }
  }

  activate(params) {
    this.dto = {
      password: null,
      passwordConfirm: null,
      token: params.token,
    };

    this.setupValidation();
  }

  async verifyLink(token: string) {
    try {
      const res = await this.authService.verifyResetPasswordLink(token);
      if (res?.success === true && res?.loginUrl) {
        this.loginUrl = res.loginUrl;
      }
      return res?.success === true;
    } catch (error) {
      return false;
    }
  }

  async setupValidation() {
    ValidationRules.ensure((it: any) => it.password)
      .required()
      .withMessage('Nytt passord påkrevd')
      .ensure((it: any) => it.passwordConfirm)
      .required()
      .withMessage('Nytt passord påkrevd')
      .on(this.dto);
  }

  async resetPassword() {
    this.errorMessage = null;

    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    if (this.dto.password !== this.dto.passwordConfirm) {
      this.errorMessage = 'Passordene er ikke like';
      return;
    }

    this.isLoading = true;

    try {
      await this.authService.resetPassword(this.dto.password, this.dto.token);
      this.isLoading = false;
      this.notificationsService.success(
        'Passord endret',
        'Ditt passord er nå endret'
      );

      if (this.loginUrl) {
        this.router.navigate(this.loginUrl);
      } else {
        this.router.navigateToRoute('login');
      }
    } catch (error) {
      this.isLoading = false;
      if (error?.response?.data?.message) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }
}
