import { autoinject } from 'aurelia-framework';
import { isBefore } from 'date-fns';
import { ProfileRes } from 'models/api';
import { ErrorService } from 'services/error-service';
import SubscriptionService from 'services/subscription-service';

@autoinject
export class Profile {
  profile: ProfileRes;
  sessions: any;
  subscriptions: any;
  cancelledSubscriptions: object[] = [];

  loading: boolean = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private errorSercice: ErrorService
  ) {}

  async activate() {
    this.getData();
  }

  async getData() {
    this.loading = true;

    try {
      this.subscriptions =
        await this.subscriptionService.getUserSubscriptions();
      this.loading = false;

      this.subscriptions.forEach((subscription) => {
        if (
          subscription.status === 'cancelled' ||
          subscription.status === 'failed'
        ) {
          if (subscription.expireDate) {
            if (!isBefore(new Date(), new Date(subscription.expireDate))) {
              this.cancelledSubscriptions.push(subscription);
            }
          } else {
            this.cancelledSubscriptions.push(subscription);
          }
        }
      });
    } catch (error) {
      this.errorSercice.handleError(error);
      this.loading = false;
    }
  }
}
