import { addDays, formatISO, parseISO } from 'date-fns';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { UpdateAddressDto, UserCharge, UserSubscription } from 'models/api';
import { ModalService } from 'services/modal-service';
import SubscriptionService from 'services/subscription-service';
import { ErrorService } from 'services/error-service';
import { NotificationsService } from 'services/notifications-service';
import { DialogService } from 'aurelia-dialog';
import { SubscriptionChangeProductModal } from 'components/subscription/subscription-change-product-modal';

interface Params {
  id: number;
}

@autoinject
export class Profile {
  private subscriptionId: number;
  private subscription: UserSubscription;
  private charges: UserCharge[];

  editAddress: boolean = false;
  canChangeProduct: boolean = true;
  nextChangeDate: string;

  constructor(
    private subscriptionService: SubscriptionService,
    private modalService: ModalService,
    private router: Router,
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService
  ) {}

  async activate({ id }: Params) {
    this.subscriptionId = id;
    this.getSubscription();
  }

  async getSubscription() {
    this.subscription =
      await this.subscriptionService.getUserSubscriptionDetails(
        this.subscriptionId
      );

    if (addDays(parseISO(this.subscription.createdOn), 3) < new Date()) {
      this.canChangeProduct = true;
    } else {
      this.canChangeProduct = false;
    }

    if (this.subscription.lastProductChangeDate) {
      this.nextChangeDate = formatISO(
        addDays(parseISO(this.subscription.lastProductChangeDate), 7)
      );
    }

    if (this.subscription.campaignProduct) {
      this.canChangeProduct = false;
    }

    this.charges = await this.subscriptionService.getUserSubscriptionCharges(
      this.subscriptionId
    );
  }

  async pausePaperDelivery(status: boolean) {
    try {
      await this.subscriptionService.pausePaperDelivery(
        this.subscriptionId,
        status
      );
      if (status) {
        this.editAddress = false;

        this.notificationsService.success(
          'Feriestopp aktivert',
          'Feriestopp er nå aktivert på ditt abonnement'
        );
      } else {
        this.notificationsService.success(
          'Feriestopp deaktivert',
          'Feriestopp er ikke lengre aktivert på ditt abonnement'
        );
      }
      this.getSubscription();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async cancelSubscription() {
    let message =
      'Er du sikker på at du vil stoppe ditt abonnement? Handlingen er permanent og abonnementet kan ikke reaktiveres.';

    if (this.subscription.product.paper) {
      message +=
        'Dersom du midlertidig vil stanse levering av papiravis, bruk Feriestopp.';
    }

    const confirmCancel = await this.modalService.confirmDanger({
      title: 'Stopp abonnement',
      body: message,
      confirmButtonText: 'Stopp abonnement',
    });

    if (!confirmCancel) {
      return;
    }

    try {
      await this.subscriptionService.cancel(this.subscriptionId);
      this.notificationsService.success(
        'Abonnementet stoppet',
        `Ditt abonnement hos ${this.subscription.client.name} er nå avsluttet`
      );
      this.getSubscription();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async changeProduct() {
    this.dialogService
      .open({
        viewModel: SubscriptionChangeProductModal,
        model: {
          currentProductId: this.subscription.product.id,
          clientSlug: this.subscription.client.slug,
          subscriptionId: this.subscription.id,
        },
        keyboard: true,
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.getSubscription();
        }
      });
  }

  async updateSubscriptionPayment() {
    try {
      const { redirectUrl } =
        await this.subscriptionService.updateSubscriptionPayment(
          this.subscriptionId
        );
      window.location.href = redirectUrl;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  statusColor(paymentStatus) {
    switch (paymentStatus) {
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'charged':
        return 'bg-green-100 text-green-800';
      case 'due':
        return 'bg-orange-100 text-orange-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      case 'partially_refunded':
        return 'bg-indigo-200 text-indigo-800';
      case 'pending':
        return 'bg-orange-100 text-orange-800';
      case 'processing':
        return 'bg-orange-100 text-orange-800';
      case 'refunded':
        return 'bg-indigo-100 text-indigo-800';
      case 'reserved':
        return 'bg-cyan-100 text-cyan-800';
    }
  }

  private toggleEditAddress() {
    if (!this.subscription.pausePaperDelivery) {
      this.editAddress = !this.editAddress;
    }
  }

  async updateSubscriptionAddress() {
    try {
      const dto: UpdateAddressDto = {
        streetName: this.subscription.address.streetName,
        streetNumber: this.subscription.address.streetNumber,
        entrance: this.subscription.address.entrance,
        floor: this.subscription.address.floor,
        flat: this.subscription.address.flat,
        co: this.subscription.address.co,
        postalCode: this.subscription.address.postalCode,
        city: this.subscription.address.city,
      };
      await this.subscriptionService.updateAddress(this.subscriptionId, dto);
      this.editAddress = false;
      this.getSubscription();
      this.notificationsService.success('Oppdatert', 'Adressen er oppdatert');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
