import { RouterConfiguration } from 'aurelia-router';
import { SubscribeParams } from './../../models/custom/subscribe';
import { autoinject, containerless, bindable } from 'aurelia-framework';
import { Product } from 'models/api';
import ProductService from 'services/product-service';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';

@containerless
@autoinject
export class SubscribeSelectProduct {
  @bindable products: Product[];

  constructor(
    private productService: ProductService,
    private router: Router,
    private eventAggregator: EventAggregator
  ) {}

  activate({ clientSlug }: SubscribeParams) {
    console.log('clientslug in select product: ', clientSlug);

    if (!clientSlug) {
      // TODO: Show error
      return;
    }

    this.getProducts(clientSlug);
  }

  attached() {}

  async getProducts(clientSlug: string) {
    this.products = await this.productService.getUserProducts(clientSlug);
  }

  async selectProduct(product: Product) {
    this.eventAggregator.publish('subscribe:selectProduct', product);
  }
}
