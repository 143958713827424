import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SubscribeFinishParams } from 'models/custom/subscribe';
import { ErrorService } from 'services/error-service';
import PollingService from 'services/polling-service';
import ProductService from 'services/product-service';
import environment from '../../../config/environment.json';
import SubscriptionService from 'services/subscription-service';

@autoinject
export class SubscribeFinish {
  errorMessage: string;
  logoUrl: string;
  clientUrl?: string;

  constructor(
    private productService: ProductService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private subscriptionService: SubscriptionService,
    private errorService: ErrorService,
    private pollingService: PollingService
  ) {}

  activate({
    finishSubscriptionToken,
    paymentid,
    client,
  }: SubscribeFinishParams) {
    if (client) {
      this.logoUrl = `${environment.apiBaseUrl}/clients/logo/${client}`;
    }

    if (finishSubscriptionToken && paymentid) {
      this.finishNets(finishSubscriptionToken);
    } else if (finishSubscriptionToken) {
      this.finish(finishSubscriptionToken);
    }
  }

  async finish(finishSubscriptionToken: string) {
    try {
      const res = await this.subscriptionService.finish(
        finishSubscriptionToken
      );
      const callbackUri = sessionStorage.getItem('callbackUri');
      if (callbackUri) {
        window.location.href = callbackUri;
      } else {
        window.location.href = res.loginUrl;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorService.handleError(error);
      }

      if (error?.response?.data?.clientUrl) {
        this.clientUrl = error.response.data.clientUrl;
        setTimeout(() => {
          window.location.href = this.clientUrl;
        }, 4000);
      }
    }
  }

  async finishNets(finishSubscriptionToken: string) {
    // This will poll while status is PENDING
    try {
      await this.pollingService.poll(
        () => this.subscriptionService.getNetsStatus(finishSubscriptionToken),
        (result) => result?.status === 'pending',
        1500
      );

      // When status is not PENDING, we do the finish process
      this.finish(finishSubscriptionToken);
    } catch (error) {
      if (error?.response?.data?.message) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorService.handleError(error);
      }

      if (error?.response?.data?.clientUrl) {
        this.clientUrl = error.response.data.clientUrl;
        setTimeout(() => {
          window.location.href = this.clientUrl;
        }, 4000);
      }
    }
  }
}
