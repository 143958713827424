import ClientService from 'services/client-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ClientInfo, Product, ProductWithCanUse } from 'models/api';
import { ErrorService } from 'services/error-service';
import ProductService from 'services/product-service';
import SubscriptionService from 'services/subscription-service';
import { SubscribeParams } from './../../models/custom/subscribe';
import environment from '../../../config/environment.json';
import UserService from 'services/user-service';
import { SubscriptionAddress } from 'models/custom/subscription-address';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { SubscriptionCompany } from 'models/custom/subscription-company';

@autoinject
export class Subscribe {
  products: ProductWithCanUse[];
  visibleProducts: ProductWithCanUse[];
  selectedProduct: ProductWithCanUse;
  subscriptionAddress: SubscriptionAddress;
  subscriptionCompany: SubscriptionCompany;
  selectedPaymentMethod: string;
  profile: any;
  hasSubscription: boolean;
  clientInfo: ClientInfo;

  progress: number = 1;

  logoUrl: string;
  errorMessage: string;

  networkError: boolean = false;
  enableTagManager: boolean = false;

  analyticsApp: any;

  constructor(
    private productService: ProductService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private subscriptionService: SubscriptionService,
    private errorService: ErrorService,
    private userService: UserService,
    private clientService: ClientService
  ) {}

  activate({ clientSlug, callbackUri, productId }: SubscribeParams) {
    this.logoUrl = `${environment.apiBaseUrl}/clients/logo/${clientSlug}`;

    this.getClientInfo(clientSlug, callbackUri);

    this.eventAggregator.subscribe(
      'subscribe:selectProduct',
      (product: ProductWithCanUse) => {
        this.selectedProduct = product;
        this.progress = product.paper ? 2 : 3;

        if (this.enableTagManager) {
          this.analyticsApp.track('selectProduct', {
            productId: product.id,
            productName: product.name,
            productPrice: product.price / 100,
          });
        }
      }
    );

    this.eventAggregator.subscribe(
      'subscribe:setSubscriptionAddress',
      (subscriptionAddress: SubscriptionAddress) => {
        this.subscriptionAddress = subscriptionAddress;
        this.progress = 3;
      }
    );

    this.eventAggregator.subscribe(
      'subscribe:setSubscriptionCompany',
      (subscriptionCompany: SubscriptionCompany) => {
        this.subscriptionCompany = subscriptionCompany;
      }
    );

    this.eventAggregator.subscribe(
      'subscribe:selectPaymentMethod',
      (paymentMethod: string) => {
        this.selectedPaymentMethod = paymentMethod;
        this.progress = 4;
        this.purchaseSubscription();

        if (this.enableTagManager) {
          this.analyticsApp.track('selectPaymentMethod', {
            paymentMethod: paymentMethod,
          });
        }
      }
    );

    this.getProducts(clientSlug).then((res) => {
      if (productId) {
        this.selectedProduct = this.products.find((p) => p.id === +productId);

        if (
          this.selectedProduct.campaignNextProduct &&
          !this.selectedProduct?.canUse
        ) {
          this.selectedProduct = this.selectedProduct.campaignNextProduct;
        }

        this.progress = this.selectedProduct.paper ? 2 : 3;

        if (this.enableTagManager) {
          this.analyticsApp.track('selectProduct', {
            productId: this.selectedProduct.id,
            productName: this.selectedProduct.name,
            productPrice: this.selectedProduct.price / 100,
          });
        }
      }
    });

    if (callbackUri) {
      sessionStorage.setItem('callbackUri', callbackUri);
    }

    this.getUser(clientSlug);
  }

  async getUser(clientSlug: string) {
    const profileRes = await this.userService.getUserForClient(clientSlug);
    this.profile = profileRes.profile;
    this.hasSubscription = profileRes.hasSubscription;
  }

  async getProducts(clientSlug: string) {
    try {
      this.products = await this.productService.getUserProducts(clientSlug);
      this.visibleProducts = this.products
        .filter((p) => !p.hidden)
        .map((it) => {
          if (it.campaignNextProduct && !it.canUse) {
            return it.campaignNextProduct;
          }
          return it;
        })
        .filter(
          (it, index, self) => index === self.findIndex((t) => t.id === it.id)
        );
    } catch (error) {
      if (!error.response) {
        this.errorMessage =
          'aboID er ikke tilgjengelig akkurat nå. Prøv igjen senere.';
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  async getClientInfo(slug: string, callbackUri?: string) {
    this.clientInfo = await this.clientService.getClientInfo(slug);

    const tagManagerId = this.clientInfo.tagManagerId;
    if (tagManagerId) {
      this.enableTagManager = true;

      this.analyticsApp = Analytics({
        app: 'aboID',
        plugins: [
          googleTagManager({
            containerId: tagManagerId,
          }),
        ],
      });

      /* Track a page view */
      this.analyticsApp.page();

      this.analyticsApp.track('visitSubscribePage', {
        clientSlug: slug,
        callbackUri: callbackUri,
      });
    }
  }

  async purchaseSubscription() {
    if (this.selectedPaymentMethod === 'vipps') {
      this.purchaseVipps();
    } else if (this.selectedPaymentMethod === 'nets') {
      this.purchaseNets();
    } else if (this.selectedPaymentMethod === 'invoiceCompany') {
      this.purchaseInvoiceCompany();
    }
  }

  async purchaseVipps() {
    try {
      const res = await this.subscriptionService.create({
        productId: this.selectedProduct.id,
        paymentGateway: this.selectedPaymentMethod as any,
        streetAddress: this.subscriptionAddress?.streetAddress,
        streetAddress2: this.subscriptionAddress?.streetAddress2,
        postalCode: this.subscriptionAddress?.postalCode,
        region: this.subscriptionAddress?.region,
      });

      if (this.enableTagManager) {
        this.analyticsApp.track('purchase', {
          productId: this.selectedProduct.id,
          productName: this.selectedProduct.name,
          paymentMethod: this.selectedPaymentMethod,
          value: this.selectedProduct.price / 100,
        });
      }

      // If running in test-environment, don't redirect to Vipps
      if (!environment.testing) {
        window.location.href = res.redirectUrl;
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  async purchaseNets() {
    try {
      const { subscriptionId, redirectUrl } =
        await this.subscriptionService.create({
          productId: this.selectedProduct.id,
          paymentGateway: this.selectedPaymentMethod as any,
          streetAddress: this.subscriptionAddress?.streetAddress,
          streetAddress2: this.subscriptionAddress?.streetAddress2,
          postalCode: this.subscriptionAddress?.postalCode,
          region: this.subscriptionAddress?.region,
        });

      if (this.enableTagManager) {
        this.analyticsApp.track('purchase', {
          productId: this.selectedProduct.id,
          productName: this.selectedProduct.name,
          paymentMethod: this.selectedPaymentMethod,
          value: this.selectedProduct.price,
        });
      }

      window.location.href = redirectUrl + '&language=nb-NO';
    } catch (error) {
      if (error?.response?.data?.message) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  async purchaseInvoiceCompany() {
    try {
      const { subscriptionId, redirectUrl } =
        await this.subscriptionService.create({
          productId: this.selectedProduct.id,
          paymentGateway: this.selectedPaymentMethod as any,
          streetAddress: this.subscriptionAddress?.streetAddress,
          streetAddress2: this.subscriptionAddress?.streetAddress2,
          postalCode: this.subscriptionAddress?.postalCode,
          region: this.subscriptionAddress?.region,
          companyName: this.subscriptionCompany?.name,
          companyOrgNumber: this.subscriptionCompany?.orgNumber,
          companyOrderer: this.subscriptionCompany?.orderer,
          companyReference: this.subscriptionCompany?.reference,
          companyStreetAddress: this.subscriptionCompany?.streetAddress,
          companyStreetAddress2: this.subscriptionCompany?.streetAddress2,
          companyPostalCode: this.subscriptionCompany?.postalCode,
          companyRegion: this.subscriptionCompany?.region,
          companyUseEhf: this.subscriptionCompany?.useEhf,
          companyEmail: this.subscriptionCompany?.email,
        });

      if (this.enableTagManager) {
        this.analyticsApp.track('purchase', {
          productId: this.selectedProduct.id,
          productName: this.selectedProduct.name,
          paymentMethod: this.selectedPaymentMethod,
          value: this.selectedProduct.price,
        });
      }

      window.location.href = redirectUrl;
    } catch (error) {
      if (error?.response?.data?.message) {
        this.errorMessage = error.response.data.message;
      } else {
        this.errorService.handleError(error);
      }
    }
  }

  private previousProgress() {
    this.errorMessage = null;

    if (this.progress === 4) {
      this.progress = 1;
    } else if (this.progress === 3) {
      if (this.selectedProduct?.paper) {
        this.progress--;
      } else {
        this.progress = 1;
      }
    } else if (this.progress > 1) {
      this.progress--;
    }
  }

  private redirectPrevPage() {
    const callbackUri = sessionStorage.getItem('callbackUri');
    if (callbackUri) {
      window.location.href = callbackUri;
    } else {
      window.location.href = this.clientInfo.website;
    }
  }
}
