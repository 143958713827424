import { EventAggregator } from 'aurelia-event-aggregator';
import { RouterConfiguration } from 'aurelia-router';
import { SubscribeParams } from './../../models/custom/subscribe';
import { autoinject, containerless, bindable } from 'aurelia-framework';
import { Product } from 'models/api';
import ProductService from 'services/product-service';
import { Router } from 'aurelia-router';
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { SubscriptionAddress } from 'models/custom/subscription-address';

@containerless
@autoinject
export class SubscribeForm {
  validationController: ValidationController;
  validationRules: any;
  products: Product[];
  @bindable profile: any;

  subscriptionAddress: SubscriptionAddress;

  constructor(
    private eventAggregator: EventAggregator,
    private validationControllerFactory: ValidationControllerFactory
  ) {
    this.validationController = validationControllerFactory.createForCurrentScope();
  }

  async attached() {
    console.log('profile', this.profile);
    this.subscriptionAddress = {
      streetAddress: this.profile?.streetAddress ?? null,
      streetAddress2: this.profile?.streetAddress2 ?? null,
      postalCode: this.profile?.postalCode ?? null,
      region: this.profile?.region ?? null,
    };
    await this.setupValidation();
  }

  async setupValidation() {
    ValidationRules.ensure((it: SubscriptionAddress) => it.streetAddress)
      .required()
      .withMessage('Adresse er påkrevd')

      .ensure((it: SubscriptionAddress) => it.postalCode)
      .required()
      .withMessage('Postnummer er påkrevd')
      .min(4)
      .withMessage('Ugyldig postnummer')

      .ensure((it: SubscriptionAddress) => it.region)
      .required()
      .withMessage('Poststed er påkrevd')

      .on(this.subscriptionAddress);
  }

  async setSubscriptionAddress() {
    const { valid } = await this.validationController.validate();
    if (!valid) {
      return;
    }

    this.eventAggregator.publish('subscribe:setSubscriptionAddress', this.subscriptionAddress);
  }

  getPostal(event: any) {
    setTimeout(() => {
      const postalCode = event.target.value;
      if (postalCode?.length === 4) {
        fetch(`https://webapi.no/api/v1/zipcode/${postalCode}`)
          .then((res) => res.json())
          .then((res) => {
            if (res?.data?.city) {
              this.subscriptionAddress.region = res.data.city;
            }
          })
          .catch((err) => console.log(err));
      }
    });
    return true;
  }
}
